import styled from "styled-components";
import { ToastContainer } from "react-toastify";
export const ToastStyled = styled(ToastContainer)`
    // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
    &&&.Toastify__toast-container {
        width: 390px;
        z-index: 99999;
        .Toastify__toast {
            padding: 12px 16px;
            align-items: baseline;
            gap: 16px;
            position: relative;
            border-radius: 8px;
            border: 1px solid ${({ theme }) => theme.colors.coolGray[3]};
            border-bottom: 0;
            box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 18px 32px -4px rgba(0, 0, 0, 0.10);
            cursor: default;
            
            &.Toastify__toast--success {
                :before {
                    content: 'Success';
                    color: ${({ theme }) => theme.colors.positive[7]};
                    font-family: ${({ theme }) => theme.fonts.AvenirHeavy};
                    position: absolute;
                    top: 11px;
                    left: 56px;
                }
            }
            
            &.Toastify__toast--error {
                :before {
                    content: 'Error';
                    color: ${({ theme }) => theme.colors.negative[7]};
                    font-family: ${({ theme }) => theme.fonts.AvenirHeavy};
                    position: absolute;
                    top: 11px;
                    left: 56px;
                }
            }

            &.Toastify__toast--info {
                :before {
                    content: 'Info';
                    color: ${({ theme }) => theme.colors.information[7]};
                    font-family: ${({ theme }) => theme.fonts.AvenirHeavy};
                    position: absolute;
                    top: 11px;
                    left: 56px;
                }
            }


            .Toastify__toast-body {
                display: flex;
                gap: 16px;
                margin: 0;
                padding: 0;
                align-items: self-start;
                
                .Toastify__toast-icon{
                    width: 24px;
                    height: 24px;
                }
                
                
                >div:last-child {
                    //::before {
                    //    content: 'Error'
                    //}
                    margin-top: 20px;
                    margin-bottom: 3px;
                    color: ${({ theme }) => theme.colors.deepBlue[7]};
                    font-family: ${({ theme }) => theme.fonts.AvenirRoman};
                    font-size: 12px;
                }
                
                .Toastify__toast-icon{
                    margin: 0;
                }
            }
            
        }
    }

    .Toastify__progress-bar {
        height: 4px !important;
    }



    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success{
        background: ${({ theme }) => theme.colors.positive[6]};
        height: 4px !important;
    }

    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
        background: ${({ theme }) => theme.colors.negative[6]};
    }
    
    .Toastify__progress-bar-theme--light {
        
    }
    .Toastify__progress-bar--animated {
        animation: Toastify__trackProgress linear 1 backwards;
    }
    @keyframes Toastify__trackProgress {
        0% {
            transform: scaleX(0);
        }
        100% {
            transform: scaleX(1);
        }
    }
`;